<template>
  <div class="order-management">
    <div style="padding: 8px">
      <h3 style="text-align: center">关于疫情期间的风险说明</h3>
      <p>
        由于全国疫情反复无常，时效可能存在延误的可能性，疫情期间各大服务商对于时效延误均没有赔偿服务。
      </p>
      <p>疫情期间，所有渠道均不提供购买保险服务，望知悉。</p>
      <p>
        我司拒绝收寄一切高价值物品，如通过瞒报漏报等方式擅自邮寄导致的损失等，我司将不做任何赔偿。
      </p>
      <p>
        以上内容，请大家仔细阅读并合理评估风险再进行提交，给大家造成的不变，希望大家谅解！
      </p>
    </div>
    <div style="text-align: center; margin-top: 30px">
      <button class="nq-button" style="margin-right: 50px">关闭</button>
      <button class="nq-button copy-button">继续提交</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'login',
  data() {
    return {
      showDetail: false
    }
  },

  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
@import url("./order.less");
</style>
